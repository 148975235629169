import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  TextareaAutosize,
  FormControl,
  InputLabel,
  Typography
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BPDMeetings, meetingTypes, qcStatus, typeBEopMeetings, typeBMeetings } from '../constant';
import GenericDropdown from './GenericDropdown';

const MeetingForm: React.FC<any> = ({
  meetingDate,
  meeting,
  updateMeetingData,
  saveMeetingData,
  onClose,
  handlePdfPageNo
}) => {
  const verifiedDoc = ['ToQC', 'NA'].includes(meeting.verified_document)
    ? Object.keys(meeting.source_description)[0]
    : meeting.verified_document;
  const [source, setSource] = useState(verifiedDoc);
  const [newMeetingSource, setNewMeetingSource] = useState(verifiedDoc);
  const [categories, setCategories] = useState<string[]>([]);
  const [isCategoryDisabled, setIsCategoryDisabled] = useState<boolean>(true);
  const [mode, setMode] = useState<'update' | 'create'>('update'); // New state to track mode
  const [newMeetingData, setNewMeetingData] = useState({
    application_id: meeting.application_id,
    count: 1,
    meeting_category: '',
    meeting_date: meeting.meeting_date,
    meeting_title: '',
    meeting_type: '',
    qc_comments: 'NA',
    qc_priority: 'High',
    qc_status: 'ToQC',
    qc_logic: 'Manual',
    source_description: {
      [verifiedDoc as string]: ''
    },
    verified_document: verifiedDoc
  });

  useEffect(() => {
    if (source) {
      handlePdfPageNo(source, meeting.meeting_category, meetingDate);
      updateMeetingData(meetingDate, meeting.meeting_title, 'verified_document', source);
    }
  }, []);

  // Handle source change and reset page number
  const handleSourceChange = (value: string) => {
    setSource(value);
    setNewMeetingSource(value);
    if (mode === 'update') {
      handlePdfPageNo(value, meeting.meeting_category, meetingDate);
      updateMeetingData(meetingDate, meeting.meeting_title, 'verified_document', value);
    } else {
      handlePdfPageNo(value, newMeetingData.meeting_category, newMeetingData.meeting_date);
      setNewMeetingData({
        ...newMeetingData,
        verified_document: value,
        source_description: { [value]: '' }
      });
    }
  };

  const handleSourceDescriptionChange = (description: string) => {
    setNewMeetingData({
      ...newMeetingData,
      source_description: { [newMeetingSource]: description }
    });
  };

  // Update categories based on the selected meeting_type
  useEffect(() => {
    const currentMeeting = mode === 'update' ? meeting : newMeetingData;
    if (currentMeeting.meeting_type === 'Type B') {
      setCategories(typeBMeetings);
      setIsCategoryDisabled(false);
    } else if (currentMeeting.meeting_type === 'Type B (EOP)') {
      setCategories(typeBEopMeetings);
      setIsCategoryDisabled(false);
    } else if (currentMeeting.meeting_type === 'BPD') {
      setCategories(BPDMeetings);
      setIsCategoryDisabled(false);
    } else {
      setCategories([]);
      setIsCategoryDisabled(true);
    }
  }, [meeting.meeting_type, newMeetingData.meeting_type]);

  const toggleMode = () => {
    setMode(prevMode => (prevMode === 'update' ? 'create' : 'update'));
  };

  const handleSave = () => {
    if (mode === 'update') {
      saveMeetingData(meeting, meetingDate, meeting.meeting_title, mode);
    } else {
      saveMeetingData(newMeetingData, '', '', mode);
    }
  };

  const handleValueChange = (fieldName: string, fieldValue: any) => {
    if (mode === 'update') {
      updateMeetingData(meetingDate, meeting.meeting_title, fieldName, fieldValue);
    } else {
      setNewMeetingData({ ...newMeetingData, [fieldName]: fieldValue });
    }
  };

  return (
    <Box component='form' sx={{ mt: 2, ml: 4 }}>
      <Stack spacing={2}>
        <Typography variant='h6'>
          {mode === 'update' ? 'Update Existing Meeting' : 'Create New Meeting'}
        </Typography>
        {/* Meeting Date */}

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label='Meeting Date'
            inputFormat='yyyy-MM-dd'
            value={mode === 'update' ? meeting.meeting_date : newMeetingData.meeting_date}
            onChange={newValue => handleValueChange('meeting_date', newValue)}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={params => <TextField size='small' {...params} />}
          />
        </LocalizationProvider>

        {/* Meeting Type Dropdown */}
        <GenericDropdown
          label='Meeting Type'
          options={meetingTypes}
          selectedValue={mode === 'update' ? meeting.meeting_type : newMeetingData.meeting_type}
          onChange={(newValue: any) => handleValueChange('meeting_type', newValue)}
        />

        {/* Meeting Category Dropdown */}
        <GenericDropdown
          label='Meeting Category'
          options={categories}
          selectedValue={
            mode === 'update' ? meeting.meeting_category : newMeetingData.meeting_category
          }
          onChange={(newValue: any) => handleValueChange('meeting_category', newValue)}
          disabled={isCategoryDisabled} // Disable if not 'Type B' or 'Type B(EOP)'
        />

        {/* Source Dropdown */}
        <GenericDropdown
          label='Source'
          options={
            mode === 'update'
              ? Object.keys(meeting.source_description)
              : Object.keys(newMeetingData.source_description)
          }
          selectedValue={mode === 'update' ? source : newMeetingSource}
          onChange={(newValue: any) => handleSourceChange(newValue)}
          disabled={mode === 'create'}
        />
        <TextField
          label='Verified Document'
          disabled
          size='small'
          value={mode === 'update' ? meeting.verified_document : newMeetingData.verified_document}
        />
        <FormControl>
          <InputLabel shrink htmlFor='meeting-description'>
            Description
          </InputLabel>
          <TextareaAutosize
            placeholder='Add Description of Meeting'
            disabled={mode === 'update'}
            value={
              mode === 'update'
                ? meeting.source_description[source]
                : newMeetingData.source_description[newMeetingSource]
            }
            style={{ padding: 8 }}
            onChange={e => handleSourceDescriptionChange(e.target.value)}
          />
        </FormControl>
        <TextField
          label='Curation Comments'
          multiline
          size='small'
          placeholder='Add Curation Comments'
          onChange={e => handleValueChange('qc_comments', e.target.value)}
          value={mode === 'update' ? meeting.qc_comments : newMeetingData.qc_comments}
        />
        {/* QC Status Dropdown */}
        <GenericDropdown
          label='QC Status'
          options={qcStatus}
          selectedValue={mode === 'update' ? meeting.qc_status : newMeetingData.qc_status}
          onChange={(newValue: any) => handleValueChange('qc_status', newValue)}
        />

        <Button onClick={handleSave} variant='contained'>
          {mode === 'update' ? 'Save' : 'Create'}
        </Button>

        <Button onClick={toggleMode} variant='outlined'>
          {mode === 'update' ? 'Switch to Create New Meeting' : 'Switch to Update Existing Meeting'}
        </Button>

        <Button color='error' onClick={onClose}>
          Close
        </Button>
      </Stack>
    </Box>
  );
};

export default React.memo(MeetingForm);
