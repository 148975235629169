import { Meeting } from './interface';

const flattenData = (data: { body: Record<string, Record<string, Meeting[]>> }): Meeting[] => {
  return Object.entries(data).flatMap(([committee, yearsData]) =>
    Object.entries(yearsData).flatMap(([year, meetings]: any) =>
      meetings.map((meeting: any) => ({
        ...meeting,
        committee_name: committee,
        year
      }))
    )
  );
};

export const formatSectionKey = (sectionKey: string): string =>{
  if(!sectionKey) return ""

  // Remove the "section_" prefix and split by underscores
  const parts = sectionKey.replace("section_", "").split("_");

  // Join the parts with a dot and capitalize the first word
  return `Section ${parts.join(".")}`;
}

const isFieldMissing = (field?: string): boolean =>
  !field || field === 'NA';

const hasMissingFields = (applicationData: any): boolean => {
  const fieldsToCheck: (keyof any)[] = [
    'active_ingredient',
    'authorization_partner',
    'brand_name',
    'product_number',
    'sponsor_name'
  ];

  return fieldsToCheck.some(field => isFieldMissing(applicationData[field]));
};

export const isHighPriority = (subSectionData: any): boolean => {
  const { application_details, scope, section_number } = subSectionData;

  return (
    (application_details?.length ?? 0) > 1 ||
    (application_details?.length === 1 && hasMissingFields(application_details[0])) ||
    isFieldMissing(scope) ||
    ['15.1.', '5.1.'].includes(section_number ?? '')
  );
};

export default flattenData;
