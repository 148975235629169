const styles = {
  root: {
    '& .MuiDataGrid-columnHeaderTitle': {
      color: 'primary.main',
      fontSize: 16,
      fontWeight: 'bold'
    },
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: 26,
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiTablePagination-toolbar': {
      color: 'primary.main',
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: 'primary.main',
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-columnsContainer': {
        color: 'primary.lightBluish',
        background: 'white.smoke'
      }
    }
  },
  button: {
    background: 'primary.lightBluish',
    color: 'white.main',
    '&:hover': {
      color: 'white.main'
    }
  },

  Heading: {
    color: 'primary.lightBluish',
    textAlign: 'left',
    fontWeight: '600'
  },
  paper: {
    marginTop: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    paddingTop: 2
  },
  submit: {
    margin: 2,
    background: 'primary.lightBluish',
    color: 'white.main'
  },
  dialog: {
    background: 'white.main'
  },
  actionBtn: {
    background: 'primary.lightBluish',
    color: 'white.main',
    textTransform: 'capitalize',
    fontWeight: 'bold'
  },
  changedText: {
    color: 'red.main',
    fontWeight: 'bold'
  }
};

export default styles;
