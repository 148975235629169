import { useState, useCallback, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { addKeyAttributes, getKeyAttributes } from '../../../api/regulatory_information';

const useMeetingData = (appKey: any) => {
  const { dispatch } = useContext<any>(Store);
  const [meetingsData, setMeetingsData] = useState<any>({});
  const [user, setUser] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      const authUser = await Auth.currentUserInfo();
      setUser({
        curator_name: authUser.attributes['custom:user'],
        curator_email: authUser.attributes.email
      });
    })();
  }, []);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  }, []);

  const handleError = (status: number) => {
    const message =
      status === 404
        ? 'Could not find attributes for the given key'
        : 'An error occurred while fetching data';
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message }
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getKeyAttributes(appKey);
      if (response.data.Status === 200) {
        setMeetingsData(response.data.Success);
      } else {
        handleError(response.data.Status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const saveMeetingData = async (
    data: any,
    date?: any,
    meetingTitle?: any,
    mode: string = 'update'
  ) => {
    try {
      // eslint-disable-next-line camelcase
      const meeting_title = `${data.meeting_type}:${data.meeting_category}`;
      const payload = {
        ...data,
        // eslint-disable-next-line camelcase
        meeting_title,
        curator_name: user?.curator_name,
        curator_email: user?.curator_email
      };
      const response = await addKeyAttributes(payload);
      if (response.data.Status === 200) {
        if (mode === 'update') {
          setMeetingsData((prevData: any) => ({
            ...prevData,
            [date]: {
              ...prevData[date],
              [meetingTitle]: { ...payload }
            }
          }));
        }
        alert('Meeting data saved successfully', 'success');
        getData();
      }
    } catch (e: any) {
      alert(e.message, 'error');
    }
  };

  const updateMeetingData = async (
    date: string,
    meetingTitle: string,
    field: string,
    value: any
  ) => {
    const updatedMeeting = {
      ...meetingsData[date][meetingTitle],
      [field]: value
    };

    setMeetingsData((prevData: any) => ({
      ...prevData,
      [date]: {
        ...prevData[date],
        [meetingTitle]: { ...updatedMeeting }
      }
    }));
  };

  return {
    meetingsData,
    saveMeetingData,
    updateMeetingData,
    loading
  };
};

export default useMeetingData;
