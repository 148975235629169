/* eslint-disable import/prefer-default-export */
import makeRequest from './client';

export const getLatestUpdates = (payload: any) =>
  makeRequest('/source-validation', 'POST', payload);

export const getHtmlContent = (payload: any) =>
  makeRequest('/source-validation/html', 'POST', payload);

export const submitError = (payload: any) =>
  makeRequest('/source-validation/error-log', 'POST', payload);

export const getErrorLog = () => makeRequest('/source-validation/error-log/2', 'GET');

export const checkSectionSeparation = (payload: any) =>
  makeRequest('/source-validation/section-separation', 'POST', payload);
