/* eslint-disable react/require-default-props */
// Define interfaces for the props
import React from 'react';
import { Typography, Stack, Box, Tooltip, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import LoadingButton from '@mui/lab/LoadingButton';
import SyncAlt from '@mui/icons-material/SyncAlt';
import styles from '../styles/productNameCuration.styles';
import { artgWebsitePrefix } from '../constants';

interface ProductNameGridByAffixProps {
  matchList: any[]; // Specify a more detailed type based on your data structure
  trimType: string;
  trimValue: string;
  loading: boolean;
  applyChanges: () => void;
  rowSelectionModel: any[]; // Specify a more detailed type if possible
  datagridSelection: any;
  toggleOriginalProductName: boolean;
  height?: string;
}

const ProductNameGridByAffix: React.FC<ProductNameGridByAffixProps> = ({
  matchList,
  trimType,
  trimValue,
  loading,
  applyChanges,
  rowSelectionModel,
  datagridSelection,
  toggleOriginalProductName,
  height = '60vh'
}) => {
  if (matchList.length === 0) {
    return null; // Or render an alternative UI, like a message saying "No data available."
  }

  const DATAGRID_COLUMN_AFFIX = [
    {
      field: 'artg_id',
      headerName: 'ARTG ID',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Link href={`${artgWebsitePrefix}${params.row.artg_id}`} target='_blank'>
          <Typography>{params.row.artg_id}</Typography>
        </Link>
      )
    },
    {
      field: 'product_name',
      headerName: 'Previous Product Name',
      width: 450,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Tooltip title={params.row.product_name} placement='top-start'>
            <Typography variant='subtitle2' sx={styles.changedText}>
              {params.row.product_name}
            </Typography>
          </Tooltip>
        );
      }
    },
    {
      field: 'after_product_name',
      headerName: 'After change Product Name',
      width: 450,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        const productNameToUse = toggleOriginalProductName
          ? params.row.original_product_name
          : params.row.product_name;
        const slicedProductName = productNameToUse.slice(
          0,
          trimType === 'prefix' ? trimValue.length : -trimValue.length
        );
        return (
          <Tooltip title={slicedProductName} placement='top-start'>
            <Typography variant='subtitle2' color='primary' fontWeight='bold'>
              {productNameToUse.toLowerCase().trim() === trimValue.toLowerCase().trim()
                ? productNameToUse
                : slicedProductName}
            </Typography>
          </Tooltip>
        );
      }
    },

    {
      field: 'original_product_name',
      headerName: 'Original Product Name',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'logic_used',
      headerName: 'Extraction Logic Used',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  return (
    <Stack spacing={2}>
      <Stack direction='row' justifyContent='space-between' spacing={2}>
        <Stack>
          <Typography variant='subtitle2' fontWeight='bold'>
            Similar rows with {trimType}:{' '}
            <Typography component='span' variant='subtitle2' fontWeight='bold' color='primary'>
              {trimValue}
            </Typography>
          </Typography>
          <Typography variant='subtitle2'>
            Clicking on Apply Changes will{' '}
            {trimType === 'prefix'
              ? 'keep only prefix for all product names'
              : 'remove suffix from all rows'}
            .
          </Typography>
        </Stack>
        <LoadingButton
          startIcon={<SyncAlt />}
          loading={loading}
          loadingPosition='start'
          size='small'
          variant='contained'
          color='primary'
          sx={styles.actionBtn}
          onClick={applyChanges}>
          Apply Changes
        </LoadingButton>
      </Stack>
      <Box sx={{ height, width: '100%' }}>
        <DataGrid
          rows={matchList}
          columns={DATAGRID_COLUMN_AFFIX as any}
          pagination
          getRowId={(row: any) => row.artg_id}
          checkboxSelection
          selectionModel={rowSelectionModel as any}
          onSelectionModelChange={(newRowSelectionModel: any) =>
            datagridSelection(newRowSelectionModel)
          }
        />
      </Box>
    </Stack>
  );
};

export default ProductNameGridByAffix;
