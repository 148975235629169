import { useState, useCallback, useContext, useEffect } from 'react';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { getEMACommitteesData } from '../../../api/EmaCommittees';
import flattenData from '../utils';

const useCommitteeData = () => {
  const { dispatch } = useContext<any>(Store);
  const [meetingsData, setMeetingsData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  }, []);

  const handleError = (status: number) => {
    const message =
      status === 404 ? 'Could not any meetings' : 'An error occurred while fetching data';
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message }
    });
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getEMACommitteesData();
      if (response.data.status === 200) {
        const formattedData = flattenData(response.data.body);
        setMeetingsData(formattedData);
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return {
    meetingsData,
    loading
  };
};

export default useCommitteeData;
