import React, { useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Typography,
  Divider
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ApplicationDetails from './ApplicationDetails';
import EditableArrayField from './EditableArrayField';
import EditableTextField from './EditableTextField';
import LinkData from './LinkData';
import GenericDropdown from '../../RegulatoryInformation/components/GenericDropdown';
import { isHighPriority } from '../utils';

interface SectionItemProps {
  sectionData: any;
  onSave: (data: any) => void;
  onDelete: any;
  index: number;
}
const textFields = ['rapporteur', 'co_rapporteur', 'scope', 'action', 'background', 'discussion'];
const qcStatus = ['ToQC', 'QCed'];
const SectionItem: React.FC<SectionItemProps> = ({ sectionData, onSave, onDelete, index }) => {
  const [sectionDetails, setSectionDetails] = useState(sectionData);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandToggle = () => {
    setIsExpanded(prev => !prev);
  };

  const handleFieldChange = (field: string, value: any) => {
    setSectionDetails((prev: any) => ({ ...prev, [field]: value }));
  };

  const handleSave = () => {
    onSave(sectionDetails);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleExpandToggle}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{`Sub Section ${sectionDetails.sub_section_number || 'Untitled'} (${
          sectionDetails.qc_status
        }) (Priority:${isHighPriority(sectionDetails) ? 'High' : 'Low'})`}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: 'flex', flexDirection: 'column' }}>
        {isExpanded && (
          <>
            {textFields.map(field => (
              <EditableTextField
                label={field}
                key={field}
                value={sectionDetails[field]}
                onChange={(value: any) => handleFieldChange(field, value)}
              />
            ))}
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />

            <EditableArrayField
              label='Summary'
              values={sectionDetails.summary || []}
              onChange={(newValues: any) => handleFieldChange('summary', newValues)}
            />
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            <EditableArrayField
              label='Post Meeting Notes'
              values={sectionDetails.post_meeting_notes || []}
              onChange={(newValues: any) => handleFieldChange('post_meeting_notes', newValues)}
            />
            <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
            <ApplicationDetails
              applicationDetails={sectionDetails.application_details}
              onUpdate={updatedAppDetails =>
                handleFieldChange('application_details', updatedAppDetails)
              }
              pracEventPdfSectionContentId={sectionDetails.id}
              applicationTitle={sectionDetails.application_title}
              sponsorNameData={sectionDetails.sponsor_name_data}
              onDelete={onDelete}
            />
            <Divider style={{ marginTop: '8px', marginBottom: '8px' }} />
            <LinkData
              linkData={sectionDetails.link_data}
              onUpdate={updatedAppDetails => handleFieldChange('link_data', updatedAppDetails)}
              emaCommitteeEventPdfId={sectionDetails.ema_committee_event_pdf_id}
              onDelete={onDelete}
            />
            <Divider style={{ marginTop: '8px', marginBottom: '16px' }} />
            <GenericDropdown
              label='QC Status'
              options={qcStatus}
              selectedValue={sectionDetails.qc_status}
              onChange={(value: any) => handleFieldChange('qc_status', value)}
            />
            <Button variant='contained' color='primary' onClick={handleSave} sx={{ marginTop: 1 }}>
              Save
            </Button>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(SectionItem);
