import React, { useState } from 'react';
import { Box, Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SectionItem from './SectionItem';
import { formatSectionKey } from '../utils';

const SectionItemWrapper: React.FC<any> = ({ sectionData, header, onSaveSectionData,onDelete }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpandToggle = () => {
    setIsExpanded(prev => !prev);
  };

  return (
    <Box sx={{ marginBottom: 2, border: '1px solid #ccc', padding: 2, borderRadius: 2 }}>
      <Accordion expanded={isExpanded} onChange={handleExpandToggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>{formatSectionKey(header) || 'Untitled Section'}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isExpanded && sectionData?.map((data: any, index: number) => (
            <Box key={data.id}>
              <SectionItem sectionData={data} onSave={onSaveSectionData} onDelete={onDelete} index={index} />
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default React.memo(SectionItemWrapper);
