import React from 'react';
import { Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';


const GenericDropdown: React.FC<any> = ({
  label,
  options,
  selectedValue,
  onChange,
  disabled=false
}) => {
  return (
    <Box>
      <FormControl fullWidth size="small" disabled={disabled}>
        <InputLabel id={`${label}-select-label`}>{label}</InputLabel>
        <Select
          labelId={`${label}-select-label`}
          value={selectedValue}
          onChange={(e) => onChange(e.target.value as string)}
          label={label}
        >
          {/* Map through options strings to render MenuItem options */}
          {options?.map((option: string, index: number) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(GenericDropdown);