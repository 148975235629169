import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import MeetingForm from './MeetingForm';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const MeetingItem: React.FC<any> = ({
  handlePdfPageNo,
  meetingDate,
  meetingItems,
  updateMeetingData,
  saveMeetingData,
}) => {
  // State to track the currently expanded meeting
  const [expandedMeetingId, setExpandedMeetingId] = useState<number | null>(null);

  // Function to handle expanding and collapsing the meeting form
  const handleToggleExpand = (meetingId: number) => {
    // Toggle the clicked meeting item and collapse others
    setExpandedMeetingId((prev) => (prev === meetingId ? null : meetingId));
  };
  
  const [showForm, setShowForm] = useState(true);

  const handleClose = () => {
    setShowForm(false);
    handlePdfPageNo('', '', '');
  };

  return (
    <Box mt={2} ml={4}>
      {Object.keys(meetingItems).map((meeting: any) => (
        <Box key={meetingItems[meeting].id} display="flex" flexDirection="column" mt={1} alignItems="start">
          {/* Heading and Expand/Collapse Button */}
          <Box display="flex" alignItems="center">
            <IconButton
              onClick={() => handleToggleExpand(meetingItems[meeting].id)}
              size="small"
              sx={{ mr: 1 }}
            >
              {expandedMeetingId === meeting.id ? (
                <RemoveCircleOutlineIcon color="primary" />
              ) : (
                <AddCircleOutlineIcon color="primary" />
              )}
            </IconButton>
            <Typography>
              <b>Meeting Type:</b> {meetingItems[meeting].meeting_type} - {meetingItems[meeting].meeting_category}{' '}
              {'(Priority: ' + meetingItems[meeting].qc_priority + ')'}
            </Typography>
          </Box>

          {/* Conditionally render the MeetingForm based on whether the meeting is expanded */}
          {expandedMeetingId === meetingItems[meeting].id && showForm && (
            <MeetingForm
              meetingDate = {meetingDate}
              meeting={meetingItems[meeting]}
              updateMeetingData={updateMeetingData}
              saveMeetingData={saveMeetingData}
              setExpandedMeetingId={setExpandedMeetingId}
              onClose={handleClose}
              handlePdfPageNo= {handlePdfPageNo}
            />
          )}
        </Box>
      ))}
    </Box>
  );
};

export default React.memo(MeetingItem);
