import { useState, useCallback, useContext } from 'react';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { getEMACommitteesGPTApplicationData } from '../../../api/EmaCommittees';

const useGPTData = () => {
  const { dispatch } = useContext<any>(Store);
  const [loading, setLoading] = useState<boolean>(false);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  }, []);

  const handleError = () => {
    const message = 'An error occurred while fetching GPT data';
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message }
    });
  };

  const getData = async (payload: any) => {
    setLoading(true);
    let resp = []

    try {
      const response = await getEMACommitteesGPTApplicationData(payload);
      if (response.data.status === 200) {
        resp = response.data.body;
      } else {
        handleError();
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
      return resp;
    }
  };

  return {
    getData,
    loading
  };
};

export default useGPTData;
