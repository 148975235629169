import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid/DataGrid';

import LinearProgress from '@material-ui/core/LinearProgress';
import Dialog from '@mui/material/Dialog';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { Auth } from 'aws-amplify';

import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

import Styles from './styles/CategorizationTable.styles';

import themev5 from '../../themev5';

import Store from '../../store';
import Actions from '../../store/actions';
import { fetchRouteDetails, insertRouteDetails } from '../../api/Cder';
import FormulationRoutesDialog from './FormulationRoutesDialog';
import { FORMULATION_ROUTE_STATUS } from './constants';

const RouteCuration = () => {
  const { dispatch } = useContext<any>(Store);

  const classes = Styles();

  const [status, setStatus] = useState('all');

  const [routeData, setRouteData] = useState<Array<any>>([]);
  const [optionList, setOptionList] = useState<Array<any>>([]);
  const [totalRowCount, setTotalRowCount] = useState();
  const [loading, setLoading] = useState(true);

  const [openEdit, setOpenEidt] = useState(null);
  const rowsPerPageOptions = [10, 20, 50, 100, 200];

  const closeEdit = () => {
    setOpenEidt(null);
  };

  const handleEditClick = async (data: any) => {
    setOpenEidt(data);
  };

  const columns = [
    {
      field: 'route_text',
      headerName: 'Routes',
      width: 500,
      headerAlign: 'center',
      align: 'center'
    },

    {
      field: 'route_mapping',
      headerName: 'Route Mapping',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box display='flex' justifyContent='end' width='100%'>
            {params.row.route_mapping && params.row.route_mapping.length
              ? params.row.route_mapping.map((cat: string) => (
                  <Chip label={cat} key={cat} sx={{ mr: 0.5, mt: 0.5 }} />
                ))
              : null}
          </Box>
        );
      }
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Actions',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box
            style={{
              margin: '3px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              variant='contained'
              onClick={() => {
                handleEditClick(params.row);
              }}>
              <Typography variant='body1'>Edit</Typography>
            </Button>
          </Box>
        );
      }
    }
  ];

  const getOption = (data: any) => {
    if (data) {
      const options = data
        ?.map((item: any) => {
          const optionText = item?.route_text;
          const splitTerms = optionText.split(',').map((term: string) => term.trim());
          return [optionText, ...splitTerms];
        })
        .reduce((acc: any, val: any) => acc.concat(val), []);

      return Array.from(new Set(options));
    }
    return [];
  };

  const fetchRouteData = async () => {
    try {
      setLoading(true);
      const result = await fetchRouteDetails();
      if (result === undefined) {
        throw new Error('Result is undefined');
      }
      if (result.data && result.data.body) {
        const resultData = result.data.body;
        setTotalRowCount(resultData.length);
        setRouteData(resultData);
        setOptionList(getOption(resultData));
      } else {
        throw Error('No data');
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRouteData();
  }, []);

  const getDataGridRecord = () => {
    if (status === 'all') {
      return routeData;
    }
    return routeData.filter((row: any) => row?.qc_status === status);
  };

  const handleFormSubmit = async (formData: any) => {
    const user = await Auth.currentUserInfo();
    setLoading(true);
    setOpenEidt(null);
    const payload = {
      route_text: formData.id,
      route_mapping: formData.mapping,
      qc_status: formData.qcStatus,
      curator_email: user.attributes.email,
      curator_name: user.attributes['custom:user']
    };
    try {
      const response = await insertRouteDetails(payload);
      if (response.data?.error) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: response.data.message }
        });
      } else if (response.data?.status === 200) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfuly updated the record', color: 'success' }
        });
        await fetchRouteData();
      } else {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Something went worng!' }
        });
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went worng!' }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Grid container>
        <Grid item xl={10} lg={10}>
          <Box display='flex' ml={2} mt={10} alignItems='center'>
            <TextField
              variant='outlined'
              id='status'
              label='QC Status'
              select
              value={status}
              onChange={e => setStatus(e.target.value)}
              InputProps={{
                style: { fontSize: 16, minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: 16 }
              }}>
              {FORMULATION_ROUTE_STATUS.map((item: any) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </TextField>
          </Box>
        </Grid>
      </Grid>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={getDataGridRecord()}
          columns={columns as any}
          rowCount={totalRowCount}
          getRowId={row => row.route_text}
          getRowHeight={() => 'auto'}
          loading={loading}
          rowsPerPageOptions={rowsPerPageOptions}
          getRowClassName={() => `table-rows`}
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={!!openEdit}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='sm'
          fullWidth>
          {openEdit ? (
            <FormulationRoutesDialog
              type='route'
              data={openEdit}
              optionList={optionList}
              closeEdit={closeEdit}
              handleSubmit={handleFormSubmit}
            />
          ) : null}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(RouteCuration);
