/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

// mui
import {
  Stack,
  Typography,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Slide,
  IconButton,
  Dialog,
  Grid,
  Tab,
  Box
} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

// api
import { getHtmlContent } from '../../../api/source_validation';

// other conmponents
import DataListContent from './DataListContent';
import HtmlContent from './HtmlContent';
import ErrorForm from './ErrorForm';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LatestUpdatesList = ({
  latestUpdates,
  setSelectedIdentifier,
  selectedIdentifier
}: {
  latestUpdates: any;
  setSelectedIdentifier: any;
  selectedIdentifier: any;
}) => {
  const [open, setOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState('' as any);
  const [value, setValue] = React.useState('application_details');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateFromSource = async (app: any) => {
    setSelectedIdentifier(app);

    const response = await getHtmlContent({
      url: app.application_details.source_url,
      source: 'eu'
    });
    if (response.status === 200) {
      setHtmlContent(response.data.body.html);
    }
    handleClickOpen();
  };

  return (
    <Stack>
      <List>
        {latestUpdates &&
          latestUpdates?.map((app: any) => (
            <ListItem
              key={app.application_details.product_number}
              sx={{ border: '1px solid gray', borderRadius: 0, p: 0, mt: 0.5 }}
              disablePadding
              secondaryAction={
                <Button
                  onClick={() => validateFromSource(app)}
                  variant='contained'
                  size='small'
                  sx={{ textTransform: 'capitalize', color: 'white.main' }}>
                  Validate from Source
                </Button>
              }>
              <ListItemButton sx={{ width: '20%' }}>
                <ListItemText
                  primary={`${app.application_details.product_name} - ${app.application_details.product_number}`}
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Stack spacing={2} p={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='subtitle1' fontWeight='bold'>
              {selectedIdentifier?.application_details.product_number}
            </Typography>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Stack>
          <ErrorForm
            entityDetails={selectedIdentifier?.application_details}
            errorLogs={selectedIdentifier?.error_logs}
          />
          <Grid container>
            <Grid item xs={5} p={1}>
              <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label='lab API tabs example'>
                      <Tab label='Application Details' value='application_details' />
                      <Tab label='Documents' value='documents' />
                    </TabList>
                  </Box>
                  <TabPanel value='application_details'>
                    <Stack spacing={2}>
                      <Typography variant='subtitle1'>
                        Product Name: {selectedIdentifier?.application_details.product_name}
                      </Typography>
                    </Stack>
                  </TabPanel>
                  <TabPanel value='documents'>
                    <DataListContent data={selectedIdentifier} />
                  </TabPanel>
                  <TabPanel value='3'>Item Three</TabPanel>
                </TabContext>
              </Box>
            </Grid>
            <Grid item xs={7} p={1}>
              <HtmlContent content={htmlContent} selectedIdentifier={selectedIdentifier} />
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default LatestUpdatesList;
