// eslint-disable-next-line import/prefer-default-export
export const qcStatus = ['Invalid', 'Escalate', 'ToQC', 'QCed'];
export const meetingTypes = [
  'Type A',
  'Type B',
  'Type B (EOP)',
  'Type C',
  'Type D',
  'BPD',
  'INTERACT',
  'Unknown'
];
export const meetingCategories = [
  'Pre-BLA',
  'Pre-NDA',
  'Pre-IND',
  'EOP1',
  'EOP2',
  'Pre-Phase 3',
  'Other',
  'NA'
];
export const typeBMeetings = ['Pre-BLA', 'Pre-NDA', 'Pre-IND', 'Other'];
export const typeBEopMeetings = ['EOP1', 'EOP2', 'EOP3', 'Pre-Phase 3'];
export const BPDMeetings = ['Type 1', 'Type 2a', 'Type 2b', 'Type 3', 'Type 4'];
