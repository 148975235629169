/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// muiv5
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

// api
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { Button, FormControl, InputLabel } from '@mui/material';
import styles from './styles/Homepage.styles';
import VivproDatagrid from '../Datagrid';
import useCommitteeData from './hooks/useCommitteeData';
import { Meeting } from './interface';
import { committees, qcStatus, years } from './constants';

const HomepageTableView = () => {
  const history = useHistory();

  const { meetingsData, loading } = useCommitteeData();

  const [committee, setCommittee] = useState('All');
  const [year, setYear] = useState('All');
  const [status, setStatus] = useState('All');
  const [filteredData, setFilteredData] = useState<Meeting[]>(meetingsData);

  const filterData = (
    selectedCommittee: string,
    selectedYear: string,
    selectedStatus: string,
    data: Meeting[]
  ) => {
    let currentData = data;

    // Filter by committee
    if (selectedCommittee !== 'All') {
      currentData = currentData.filter((dt: { committee_name: string }) =>
        dt.committee_name.toLowerCase().includes(selectedCommittee.trim().toLowerCase())
      );
    }

    // Filter by year
    if (selectedYear !== 'All') {
      currentData = currentData.filter((dt: { year: string }) =>
        dt.year.toLowerCase().includes(selectedYear.trim().toLowerCase())
      );
    }

    // Filter by status
    if (selectedStatus !== 'All') {
      currentData = currentData.filter((dt: { qc_status: string }) =>
        dt.qc_status.toLowerCase().includes(selectedStatus.trim().toLowerCase())
      );
    }

    setFilteredData(currentData);
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    const selectedStatus = event.target.value as string;
    setStatus(selectedStatus);
    filterData(committee, year, selectedStatus, meetingsData);
  };

  const handleYearChange = (event: SelectChangeEvent) => {
    const selectedYear = event.target.value as string;
    setYear(selectedYear);
    filterData(committee, selectedYear, status, meetingsData);
  };

  const handleCommitteeChange = (event: SelectChangeEvent) => {
    const selectedCommittee = event.target.value as string;
    setCommittee(selectedCommittee);
    filterData(selectedCommittee, year, status, meetingsData);
  };

  // Column Mappings
  const COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 100,
      flex: 0.1,
      headerAlign: 'center',
      // eslint-disable-next-line no-unused-vars
      renderCell: ({ row }: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/ema/committees/${row.committee_name}/${row.id}`);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'date',
      headerName: 'Meeting Month',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'year',
      headerName: 'Meeting Year',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'title',
      headerName: 'Title',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Qc Status',
      headerAlign: 'center',
      width: 200,
      flex: 0.2,
      align: 'center'
    }
  ];

  useEffect(() => {
    setFilteredData(meetingsData);
  }, [meetingsData]);

  return (
    <Box height='10vh'>
      <Box style={{ height: '50vh', width: '95%', paddingTop: '10vh', paddingLeft: '10vh' }}>
        <Box>
          <Typography sx={styles.Typography}>EMA Committees Meetings</Typography>
        </Box>
        <Box sx={styles.root} pr={2}>
          <Stack direction='column' alignItems='flex-start' spacing={2}>
            <Stack direction='row' spacing={2} pt={3} width='100%'>
              <FormControl>
                <InputLabel id='status'>Committee</InputLabel>
                <Select
                  labelId='committee_name'
                  name='committee_name'
                  id='committee_name'
                  value={committee}
                  label='Committee'
                  sx={styles.select}
                  onChange={handleCommitteeChange}>
                  {committees?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='status'>Year</InputLabel>
                <Select
                  labelId='year'
                  name='year'
                  id='year'
                  value={year}
                  label='Status'
                  sx={styles.select}
                  onChange={handleYearChange}>
                  {years?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl>
                <InputLabel id='status'>Status</InputLabel>
                <Select
                  labelId='qc_status'
                  name='qc_status'
                  id='qc_status'
                  value={status}
                  label='Status'
                  onChange={handleStatusChange}>
                  {qcStatus?.map(option => {
                    return (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Stack>
            <Box height='70vh' width='100%'>
              <VivproDatagrid
                rows={filteredData}
                loading={loading}
                key='id'
                columnsMapping={COLUMNS_MAPPINGS as any}
                rowId='id'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(HomepageTableView);
