import React from 'react';
import { TextField } from '@mui/material';

interface EditableTextFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
}

const EditableTextField: React.FC<EditableTextFieldProps> = ({ label, value, onChange }) => {
  return (
    <TextField
      label={label}
      value={value}
      onChange={e => onChange(e.target.value)}
      multiline
      size='small'
      margin='normal'
      variant='outlined'
    />
  );
};

export default React.memo(EditableTextField);
