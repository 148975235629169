// eslint-disable-next-line import/prefer-default-export
export const QC_OPTIONS = [
  { label: 'To QC', value: 'to_qc' },
  { label: 'QCed', value: 'qced' },
  { label: 'No Document', value: 'no_document' },
  { label: 'Section 14 Unavailable', value: 'sec_14_missing' },
  { label: 'Escalate', value: 'escalate' }
];

export const STUDY_KEY_TITLE: any = {
  has_combined_study_data: {
    title: 'Has Combined Study Data',
    inputType: 'radio-group',
    default: false,
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ]
  },
  combined_study_count: {
    title: 'Combined Study Count',
    inputType: 'number',
    default: null,
    options: []
  },
  blinding: {
    title: 'Blinding',
    inputType: 'text',
    default: null,
    options: ['Open-Label', 'Single', 'Double', 'Triple', 'Quadruple']
  },
  center: { title: 'Center', inputType: 'text', default: null, options: ['Single', 'Multi'] },
  control: { title: 'Control', inputType: 'text', default: null, options: ['Placebo', 'Active'] },
  randomized: {
    title: 'Is Randomized',
    inputType: 'radio-group',
    default: true,
    options: [
      {
        label: 'Yes',
        value: true
      },
      {
        label: 'No',
        value: false
      }
    ]
  }
};

export const ATTRIBUTE_MAPPING: any = {
  duration: {
    type: 'number'
  },
  numberofarms: {
    type: 'number'
  },
  samplesize: {
    type: 'number'
  },
  durationunit: {
    options: ['msec', 'sec', 'mins', 'hrs', 'days', 'weeks', 'months', 'years']
  },
  primaryendpoint: {
    descriptionEnabled: true,
    altTitle: 'Primary EP'
  },
  secondaryendpoint: {
    descriptionEnabled: true,
    altTitle: 'Sec. EP'
  }
};

export const ATTRIBUTE_ORDER = [
  'blinding',
  'center',
  'randomized',
  'control',
  'has_combined_study_data',
  'combined_study_count'
];

export const BOOLEAN_RADIO_GROUP_OPTIONS = [
  {
    label: 'Yes',
    value: true
  },
  {
    label: 'No',
    value: false
  }
];

export const CENTER_MAPPING: any = {
  cber: 'CBER',
  cder: 'CDER'
};

export const CENTER_OPTIONS: any = [
  { label: 'All', value: 'all' },
  { label: 'CDER', value: 'cder' },
  { label: 'CBER', value: 'cber' }
];
