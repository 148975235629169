export const qcStatus = ['All', 'ToQC', 'QCed'];
export const committees = ['All', 'PRAC', 'PDCO'];
export const years = [
  'All',
  '2024',
  '2023',
  '2022',
  '2021',
  '2020',
  '2019',
  '2018',
  '2017',
  '2016',
  '2015',
  '2014',
  '2013',
  '2012'
];
