import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// mui
import { Stack, Typography, Container, Divider } from '@mui/material';

// hooks
import useSourceValidation from '../hooks/useSourceValidation';

// other components
import LatestUpdatesList from './LatestUpdatesList';
import LatestUpdatesListLoader from './LatestUpdatesListLoader';

import { getErrorLog } from '../../../api/source_validation';

const SourceValidationLayout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    getLatestUpdatesList,
    latestUpdates,
    setSelectedIdentifier,
    selectedIdentifier,
    loading
  } = useSourceValidation();

  useEffect(() => {
    // Get current date
    const currentDate = new Date();
    const formattedCurrentDate =
      queryParams.get('toDate') ?? currentDate.toISOString().split('T')[0];
    // Subtract two days
    currentDate.setDate(currentDate.getDate() - 7);
    // Format the date as YYYY-MM-DD
    const defaultDate = queryParams.get('fromDate') ?? currentDate.toISOString().split('T')[0];
    getLatestUpdatesList(defaultDate, formattedCurrentDate);
  }, []);

  useEffect(() => {
    const fetchErrorLog = async () => {
      await getErrorLog();
    };
    fetchErrorLog();
  }, []);

  const today = new Date();
  const formattedDate = today.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <Container maxWidth='lg'>
      <Stack mt={8} spacing={2}>
        <Typography variant='subtitle1' fontWeight='bold'>
          Data source: EMA EPAR
        </Typography>

        <Divider />

        {loading && <LatestUpdatesListLoader />}
        {!loading && (
          <>
            <Typography variant='body1'>
              Showing latest updates from past {formattedDate}
            </Typography>
            <LatestUpdatesList
              latestUpdates={latestUpdates}
              setSelectedIdentifier={setSelectedIdentifier}
              selectedIdentifier={selectedIdentifier}
            />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default SourceValidationLayout;
