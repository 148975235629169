import React, { ChangeEvent, useCallback, useState } from 'react';
import {
  Autocomplete,
  Button,
  Container,
  DialogTitle,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import styles from './styles/FormulationRoutes.styles';
import { FORMULATION_ROUTE_STATUS } from './constants';

const FormulationRoutesDialog = ({ type, data, optionList, closeEdit, handleSubmit }: any) => {
  const [dataField, setDataField] = useState<Array<any>>(
    type === 'form' ? data?.formulation_mapping ?? [] : data?.route_mapping ?? []
  );
  const [status, setStatus] = useState<string>(data?.qc_status);
  const [error, setError] = useState<boolean>(false);
  const [inputError, setInputError] = useState('');

  const isValid = (name: string): boolean => {
    return /[^\w\s]$/.test(name);
  };

  const setErrorAndMessage = (errorflag: boolean, message: string) => {
    setError(errorflag);
    setInputError(message);
  };

  const handleValidation = (newInputValue: string) => {
    if (!newInputValue) {
      setErrorAndMessage(true, 'Empty strings are not allowed.');
    } else if (isValid(newInputValue)) {
      setErrorAndMessage(true, 'Special characters are not allowed.');
    } else if (newInputValue.trim() !== newInputValue) {
      setErrorAndMessage(true, 'Trailing spaces are not allowed.');
    } else if (!optionList.includes(newInputValue)) {
      setErrorAndMessage(false, 'Note that value does not bring to list of option');
    } else {
      setErrorAndMessage(false, '');
    }
  };

  const handleMultipleDropDownChange = useCallback(
    (val: any) => {
      if (val.length !== 0) {
        val.forEach((item: any) => {
          handleValidation(item);
        });
      } else {
        setErrorAndMessage(false, '');
      }
      setDataField(val);
    },
    [dataField]
  );

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' sx={styles.Heading}>
            {type === 'form' ? 'Formulation' : 'Route'}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form style={styles.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='form-route-textfield'
                label={type === 'form' ? 'Formulation text' : 'Route text'}
                value={type === 'form' ? data?.formulation_text : data?.route_text}
                disabled
                InputProps={{
                  style: { fontSize: '2vh' }
                }}
                InputLabelProps={{
                  style: { fontSize: '2vh' }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                id={type === 'form' ? 'Formulation Mapping' : 'Route Mapping'}
                fullWidth
                value={dataField}
                multiple
                freeSolo
                options={optionList}
                onChange={(event: ChangeEvent<{}>, newValue: any): void => {
                  handleMultipleDropDownChange(newValue);
                }}
                sx={{ fontSize: '2vh' }}
                ChipProps={{
                  sx: styles.autocompleteChip
                }}
                renderInput={(params: any) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label={type === 'form' ? 'Formulation Mapping' : 'Route Mapping'}
                    variant='outlined'
                    error={error}
                    helperText={inputError}
                    sx={{
                      '& .MuiFormHelperText-root': {
                        color: inputError ? '#FFA500' : undefined
                      }
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='status'
                label='Status'
                select
                value={status}
                onChange={e => setStatus(e.target.value)}
                InputProps={{
                  style: { fontSize: '2vh' }
                }}
                InputLabelProps={{
                  style: { fontSize: '2vh' }
                }}>
                {FORMULATION_ROUTE_STATUS.filter((ele: any) => ele.value !== 'all').map(
                  (item: any) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  )
                )}
              </TextField>
            </Grid>
          </Grid>
          <Button
            fullWidth
            variant='contained'
            disabled={error}
            color='primary'
            onClick={() => {
              handleSubmit({
                id: type === 'form' ? data?.formulation_text : data?.route_text,
                mapping: dataField,
                qcStatus: status
              });
            }}
            sx={styles.submit}>
            SUBMIT
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default FormulationRoutesDialog;
