import { useState, useCallback, useContext } from 'react';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { deleteEMACommitteesApplicationData, getEMACommitteesMeetingData, upsertEMACommitteesMeetingData } from '../../../api/EmaCommittees';

const useMeetingData = () => {
  const { dispatch } = useContext<any>(Store);
  const [meetingData, setMeetingData] = useState<any>({});
  const [fetchApiData, setFetchApiData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  }, []);

  const handleError = (status: number) => {
    const message =
      status === 404 ? 'Could find not any meeting' : 'An error occurred';
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message }
    });
  };

  const getData = useCallback(async (payload: any) => {
    setLoading(true);
    try {
      const response = await getEMACommitteesMeetingData(payload);
      if (response.data.status === 200) {
        setMeetingData(response.data.body);
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  const onSave = async (data: any) => {
    setLoading(true);
    try {
      const payload = {
        committee_name: 'PRAC',
        data: [data]
      };
      const response = await upsertEMACommitteesMeetingData(payload);
      if (response.data.status === 200) {
        alert('Section Data saved successfully','success');
        setFetchApiData(true)
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  const onDelete = async (data: any, type: string) => {
    try {
      const payload = {
        committee_name: 'PRAC',
        data: [
          {
            [type]: [data]
          }
        ]
      };
      const response = await deleteEMACommitteesApplicationData(payload);
      if (response.data.status === 200) {
        alert(`${type} data deleted successfully`,'success');
        return true
      } else {
        handleError(response.data.status);
      }
    } catch (e: any) {
      alert(e.message, 'error');
    } finally {
      return false;
    }
  };

  return {
    getData,
    meetingData,
    onSave,
    onDelete,
    fetchApiData,
    loading
  };
};

export default useMeetingData;
