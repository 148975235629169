import makeRequest from './client';

export const getHMALabelList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  date: string,
  original = false
) => {
  const baseURL = `/hma/`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status.toLowerCase() !== 'all') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`vin_number=${applicationNumber}`);
  }
  if (original) {
    queryParams.push(`is_original=${1}`);
  }
  if (date) {
    queryParams.push(`document_date=${date}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const fetchLabelDocs = (documentId: string, documentHash: string) => {
  return makeRequest(`/hma/get-label/${documentId}?document_hash=${documentHash}`, 'GET');
};

export const submitLabelCurationDetails = (payload: any) => {
  return makeRequest('/hma/label_section', 'POST', payload);
};

export const fetchLabelPageCurationDetails = (documentId: any, original: any) => {
  let baseURL = `/hma/processed/label/${documentId}`;
  if ([0, 1].includes(original)) {
    baseURL += `?is_original=${original}`;
  }
  return makeRequest(baseURL, 'GET');
};

export const submitLabelPageCuration = (payload: any) => {
  return makeRequest('/hma/update_processed_hma', 'POST', payload);
};
