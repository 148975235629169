import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Badge
} from '@mui/material';

import { submitError } from '../../../api/source_validation';

import useSourceValidation from '../hooks/useSourceValidation';

import { REVIEW_STATUS_OPTIONS, ERROR_DATA_TYPE_OPTIONS } from '../constants';

const ErrorForm = ({ entityDetails, errorLogs }: { entityDetails: any; errorLogs: any }) => {
  const {
    openErrorFormDialog,
    closeErrorFormDialog,
    openExistingErrors,
    handleOpenExistingErrors,
    formData,
    handleErrorFormChange,
    setOpenExistingErrors,
    openErrorForm,
    setFormData
  } = useSourceValidation();

  const submitErrorForm = async (closeError: boolean = false, errorDetails: any = {}) => {
    let payload = null;
    if (closeError) {
      payload = {
        source_validation_stats_id: 2,
        id: errorDetails.id,
        error_type: 'CLOSED',
        comment: errorDetails.error_message,
        entity: entityDetails,
        reported_by: errorDetails.reported_by
      };
    } else {
      payload = {
        source_validation_stats_id: 2,
        error_type: formData.error_data_type,
        comment: formData.error_message,
        entity: { ...entityDetails, ...{ error_details_type: formData.error_details } },
        reported_by: 'venu@vivpro.ai'
      };
    }

    await submitError(payload);
    errorLogs.push(payload);
    setFormData({
      error_data_type: '',
      error_message: '',
      error_details: {}
    });
    closeErrorFormDialog();
  };

  return (
    <Stack spacing={2} direction='row' justifyContent='flex-end' alignItems='center'>
      {/* Display the list of error reports */}
      <Badge badgeContent={errorLogs?.length ?? 0} color='error'>
        <Button
          onClick={handleOpenExistingErrors}
          disabled={!errorLogs?.length}
          variant='contained'
          size='small'
          sx={{ textTransform: 'capitalize', color: 'white.main', fontWeight: 'bold' }}>
          Existing Errors
        </Button>
      </Badge>

      {/* Display the error reports dialog */}
      <Dialog open={openExistingErrors} onClose={() => setOpenExistingErrors(false)}>
        <DialogTitle sx={{ bgcolor: 'primary.secondary' }}>Reported Errors</DialogTitle>
        <DialogContent>
          <Stack spacing={2} p={2} sx={{ minWidth: 700, width: '100%', height: 800 }}>
            <Stack spacing={2} sx={{ width: '60%' }}>
              {errorLogs?.map((err: any) => {
                return (
                  <Stack
                    spacing={0.5}
                    p={2}
                    key={err.id}
                    sx={{ border: '1px solid gray', borderRadius: 3 }}>
                    <Typography noWrap variant='subtitle1' fontWeight='bold'>
                      Error Type: {err.error_type}
                    </Typography>
                    <Typography noWrap variant='body1'>
                      Error Message: {err.comment}
                    </Typography>
                    <Typography noWrap variant='body1'>
                      Reported At: {err.reported_at}
                    </Typography>
                    <Button
                      onClick={() => submitErrorForm(true, err)}
                      variant='contained'
                      sx={{ bgcolor: 'primary.main', color: 'white.main', fontWeight: 'bold' }}>
                      Close error
                    </Button>
                  </Stack>
                );
              })}
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
      <Button
        onClick={openErrorFormDialog}
        variant='contained'
        color='error'
        size='small'
        sx={{ textTransform: 'capitalize' }}>
        Report Error
      </Button>

      {/* Display the error form dialog */}
      <Dialog open={openErrorForm} onClose={closeErrorFormDialog}>
        <DialogTitle sx={{ bgcolor: 'red.main' }}>Error Form</DialogTitle>
        <DialogContent>
          <Stack spacing={2} p={2} sx={{ width: 700, height: 800 }}>
            <FormControl>
              <InputLabel id='error-details-type-label'>Error Details Type</InputLabel>
              <Select
                labelId='error-details-type-label'
                id='error-details-type'
                required
                name='error_details'
                sx={{ width: '40%' }}
                variant='outlined'
                value={formData.error_details}
                onChange={handleErrorFormChange}>
                {ERROR_DATA_TYPE_OPTIONS.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              margin='normal'
              id='error-message'
              required
              name='error_message'
              label='Error Message'
              placeholder='Enter error message'
              sx={{ width: '80%' }}
              minRows={6}
              multiline
              value={formData.error_message}
              onChange={handleErrorFormChange}
            />
            <FormControl>
              <InputLabel id='error-data-type-label'>Review Status</InputLabel>
              <Select
                labelId='error-data-type-label'
                id='error-data-type'
                name='error_data_type'
                sx={{ width: '40%' }}
                variant='outlined'
                required
                value={formData.error_data_type}
                onChange={handleErrorFormChange}>
                {REVIEW_STATUS_OPTIONS.map((option: any) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeErrorFormDialog} color='secondary'>
            Cancel
          </Button>
          <Button onClick={() => submitErrorForm(false, {})} color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ErrorForm;
