// mui
import { Button, Box, Link } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import useSourceValidation from '../hooks/useSourceValidation';

const DataListContent = ({ data }: { data: any }) => {
  const { verifySectionSeparation } = useSourceValidation();

  const DOCUMENT_LIST_COLUMNS = [
    {
      field: 'category',
      headerName: 'Category',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'document_added',
      headerName: 'Added Date',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 's3_path',
      headerName: 'S3 Path',
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Link href={params.row.s3_path} target='_blank' rel='noopener'>
            Verify S3 link
          </Link>
        );
      }
    },
    {
      field: 'f_path',
      headerName: 'Section Separation',
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        if (params.row.category_bucket !== 'product-info') return null;
        return (
          <Button
            variant='contained'
            size='small'
            sx={{ textTransform: 'capitalize', color: 'white.main' }}
            onClick={() => verifySectionSeparation(params.row.file_path)}>
            Check Section Separation
          </Button>
        );
      }
    }
  ];

  return (
    <Box sx={{ width: '100%', height: '75vh' }}>
      <DataGrid
        rows={data?.documents?.results}
        columns={DOCUMENT_LIST_COLUMNS as any}
        pagination
        getRowId={(row: any) => row.s3_path}
      />
    </Box>
  );
};

export default DataListContent;
