import React, { useEffect, useState } from 'react';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import SBASAttributeData from './SBASAttributeData';
import useSBASInitialData from './hooks/useSBASInitialData';

// api
import { addSBASAttribueData } from '../../api/submission_details';

const SBASForm = ({ applno, submission_type, submission_number, center, documentId }: any) => {
  const [sbasData, setSBASData] = useState<
    Array<{
      document_type: { id: number; name: String };
      id: number;
      attribute_title: String;
      attribute_seq: number;
      attribute_value: string;
    }>
  >([]);
  const [inputTextData, setInputTextData] = useState<string>('');
  const [inputSeqData, setInputSeqData] = useState<any>(null);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [dataUpdateTrigger, triggerDataUpdate] = useState(false);
  const [expanded, setExpanded] = useState<boolean>(true);
  const {
    selectedAttribute,
    curatorName,
    sbasAttributes,
    documentTypeId,
    setSelectedAttribute,
    getDocumentType,
    getPossibleAttributes,
    getUserDetails,
    alertFn
  } = useSBASInitialData();

  const clearInputs = () => {
    setInputSeqData('');
    setInputTextData('');
  };

  const getAttributeSequence = (new_description: string) => {
    let new_sequence = 0;
    sbasData.forEach(({ attribute_title = '', id, attribute_seq }) => {
      if (new_description == attribute_title) {
        new_sequence = Math.max(new_sequence, attribute_seq);
      }
    });
    return new_sequence + 1;
  };

  const handleAddNewAttributeData = async () => {
    let payload = {
      applno,
      submission_type,
      submission_number,
      attribute_id: Number(selectedAttribute),
      document_type_id: Number(documentTypeId),
      attribute_value: typeof inputSeqData === 'number' && inputSeqData !== 0 ? inputSeqData : '',
      attribute_seq: getAttributeSequence(inputTextData),
      curator_name: curatorName,
      qc_status: 'QCed',
      attribute_title: inputTextData,
      center,
      document_id: documentId
    };

    const response = await addSBASAttribueData(payload).catch(console.error);
    if (response.data?.Status === 200) {
      clearInputs();
      triggerDataUpdate(prev => !prev);
    } else {
      alertFn('Failed to add SBAS attribute data', 'error');
    }
  };

  useEffect(() => {
    getDocumentType();
    getPossibleAttributes();
    getUserDetails();
  }, []);

  if (!documentTypeId || sbasAttributes.length == 0) {
    return null;
  }

  const handleChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  return (
    <Box display='flex' flexDirection={'column'} width={'100%'} marginBottom={'15px'}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'>
          <Typography variant='h6' color='black'>
            Data from section 6.1
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display='flex' marginBottom={'15px'}>
            <Select
              id='selectStatus'
              variant='standard'
              disableUnderline
              required
              disabled // disabled for now and hardcoded to Safety data for now.
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              open={materialOpen}
              onClose={() => setMaterialOpen(false)}
              onOpen={() => setMaterialOpen(true)}
              value={selectedAttribute}
              onChange={e => setSelectedAttribute(e.target.value)}>
              {sbasAttributes.map(({ attribute_id, attribute_name }) => {
                return <MenuItem value={`${attribute_id}`}>{attribute_name}</MenuItem>;
              })}
            </Select>
            <TextField
              placeholder='Sample Size'
              type={'number'}
              sx={{ width: '30%' }}
              onChange={e => {
                let val = +e.target.value;
                if (typeof val === 'number') {
                  setInputSeqData(val);
                }
              }}
              value={inputSeqData}
            />
            <TextField
              placeholder='Description'
              sx={{ width: '50%' }}
              onChange={e => setInputTextData(e.target.value)}
              value={inputTextData}
            />
            <Button
              onClick={() => handleAddNewAttributeData()}
              sx={{ color: '#33B187' }}
              disabled={!inputSeqData && !inputTextData}>
              ADD
            </Button>
            <Button color='error' onClick={() => clearInputs()}>
              Clear
            </Button>
          </Box>
          <SBASAttributeData
            applno={applno}
            documentTypeId={documentTypeId}
            attributeId={selectedAttribute}
            submission_type={submission_type}
            submission_number={submission_number}
            updateTrigger={dataUpdateTrigger}
            alertFn={alertFn}
            sbasData={sbasData}
            setSBASData={setSBASData}
            getAttributeSequence={getAttributeSequence}
            center={center}
            documentId={documentId}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default SBASForm;
