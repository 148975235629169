const openDocxOrPdfViewer = (fileUrl: string, callback: () => void) => {
  const option =
    'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
  const newWindow = window.open(fileUrl, '_blank', option);
  const timer = setInterval(() => {
    if (newWindow?.closed) {
      clearInterval(timer);
      callback();
    }
  }, 1000);
};

export default openDocxOrPdfViewer;
