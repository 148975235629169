import React, { useEffect, useState } from 'react';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewer = ({ fileUrl, initialPage = 0, defaultScale = 1 }) => {
  const [currentPage, setCurrentPage] = useState(initialPage);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js">
      <Viewer
        key={currentPage} // Forces re-render when currentPage changes
        fileUrl={fileUrl}
        initialPage={currentPage}
        defaultScale={defaultScale}
        plugins={[defaultLayoutPluginInstance]}
      />
    </Worker>
  );
};

export default PDFViewer;
