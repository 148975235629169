/* eslint-disable no-console */
import { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// api
import { getLatestUpdates, checkSectionSeparation } from '../../../api/source_validation';

import Store from '../../../store';
import Actions from '../../../store/actions';

interface Application {
  product_name: string;
  source_url: string;
  product_number: string;
  documents: any[];
}

const useSourceValidation = () => {
  const history = useHistory();
  const { dispatch } = useContext(Store) as any;

  const [latestUpdates, setLatestUpdates] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIdentifier, setSelectedIdentifier] = useState<Application | null>(null);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [openErrorForm, setOpenErrorForm] = useState(false);
  const [openExistingErrors, setOpenExistingErrors] = useState(false);
  const [formData, setFormData] = useState({
    error_data_type: '',
    error_message: '',
    error_details: {}
  });

  const getLatestUpdatesList = async (from: string, to: string) => {
    setLoading(true);
    const response = await getLatestUpdates({
      date: {}
    });
    setFromDate(from);
    setToDate(to);
    if (response.status === 200) {
      setLatestUpdates(response.data.body.results.applications);
    }
    if (response.status !== 200) {
      setError('Error fetching data from the source');
    }
    setLoading(false);
    history.push({
      pathname: '/sourceValidation',
      search: `?fromDate=${from}&toDate=${to}` // Update the search part of the URL
    });
  };

  /**
   * function to update start date category
   * @param {string} newValue is the start value that the user clicks inside the calendar
   */
  const handleStartChange = async (newValue: any) => {
    if (newValue) {
      newValue.setDate(newValue.getDate() - 1);
      const defaultDate = newValue.toISOString().split('T')[0];
      setFromDate(defaultDate);
    }
  };

  /**
   * function to update end date category
   * @param {string} newValue is the end value that the user clicks inside the calendar
   */
  const handleEndChange = async (newValue: any) => {
    if (newValue) {
      newValue.setDate(newValue.getDate() - 1);
      const defaultDate = newValue.toISOString().split('T')[0];
      setToDate(defaultDate);
    }
  };

  /**
   * function to verify section separation is available for the file path
   * @param {string} filePath is the file path to verify section separation
   */
  const verifySectionSeparation = async (filePath: string) => {
    try {
      const payload = { fpath: filePath, source: 'ema' };
      const response = await checkSectionSeparation(payload);

      if (response && response.status === 200) {
        const sectionSeparated =
          response.data && response.data.body && response.data.body?.section_separated;

        dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            color: sectionSeparated ? 'info' : 'error',
            message: `Section separation is ${
              sectionSeparated ? 'available' : 'not available'
            } for ${filePath}`
          }
        });
      } else {
        throw new Error('Invalid server response');
      }
    } catch (err: any) {
      console.error('Error verifying section separation:', error);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          color: 'error',
          message: `Failed to verify section separation for ${filePath}: ${err.message}`
        }
      });
    }
  };

  const openErrorFormDialog = () => {
    setOpenErrorForm(true);
  };

  const closeErrorFormDialog = () => {
    setOpenErrorForm(false);
  };

  const handleOpenExistingErrors = () => {
    setOpenExistingErrors(true);
  };

  const handleErrorFormChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  return {
    getLatestUpdatesList,
    handleStartChange,
    handleEndChange,
    latestUpdates,
    fromDate,
    toDate,
    setSelectedIdentifier,
    selectedIdentifier,
    loading,
    error,
    verifySectionSeparation,
    openErrorFormDialog,
    closeErrorFormDialog,
    openExistingErrors,
    handleOpenExistingErrors,
    formData,
    handleErrorFormChange,
    setOpenExistingErrors,
    setOpenErrorForm,
    openErrorForm,
    setFormData
  };
};

export default useSourceValidation;
