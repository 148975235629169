/* eslint-disable react/no-danger */

// mui
import { Stack, Link } from '@mui/material';

const HtmlContent = ({
  content,
  selectedIdentifier
}: {
  content: string;
  selectedIdentifier: any;
}) => {
  return (
    <Stack
      p={2}
      sx={{ border: '1px solid black', width: '100%', height: '90vh', overflow: 'auto' }}>
      <Stack direction='row' justifyContent='space-between'>
        <Link
          href={selectedIdentifier.application_details.source_url}
          target='_blank'
          rel='noopener'>
          Open in new tab
        </Link>
      </Stack>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Stack>
  );
};

export default HtmlContent;
