import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { debounce } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { format } from 'date-fns';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import themev5 from '../../themev5';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// Styles
import Styles from './styles/curationdashboard.styles';
import styles from '../Datagrid/Datagrid.styles';

// Store
import Store from '../../store';
import Actions from '../../store/actions';

// component
import PageEditDialog from './PageEditDialog';

// api
import qcStatusMap, { StatusMap } from './contants';
import { getHMALabelList } from '../../api/Hma';

interface Field {
  id: string;
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
}

const PageNumberCuration = () => {
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [productData, setProductData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setloading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [vinNumber, setVinNumber] = useState('');
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState<boolean>(true);
  const [selectedProductData, setSelectedProductData] = useState<object>({});
  const [status, setStatus] = useState('all');
  const [documentDate, setDocumentDate] = useState<string>('');
  const { dispatch } = useContext<any>(Store);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const handleProductNumber = (value: string) => {
    setVinNumber(value);
    handleChange();
  };

  const fields: Field[] = [
    {
      id: 'vin_number',
      label: 'Vin Number',
      value: vinNumber,
      onChange: handleProductNumber
    }
  ];

  const openEditDialog = (data: any) => {
    setSelectedProductData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true);
        const original: boolean = true;
        const result = await getHMALabelList(
          pageNo,
          pageSize,
          status,
          vinNumber,
          documentDate,
          original
        );

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setRowCount(result.data.body.total_rows);
          setProductData(resultData);
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        setloading(false);
      }
    };
    getData();
  }, [pageNo, pageSize, reload, documentDate]);

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEditDialog(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'vin_number',
      headerName: 'Vin Number',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'original_file_name',
      headerName: 'Original File Name',
      width: 450,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Qced Status',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return <Typography> {qcStatusMap(params.row.qc_status)} </Typography>;
      }
    },
    {
      field: 'document_date',
      headerName: 'Document Date',
      width: 450,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <TextField
          variant='outlined'
          id='status'
          label='QC Status'
          select
          value={status}
          onChange={(event: ChangeEvent<HTMLInputElement>): void => {
            if (event.target.value !== null) {
              setStatus(event.target.value);
              setPageNo(0);
              handleChange();
            }
          }}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}>
          {Object.entries(StatusMap).map(([key, value]: any) => (
            <MenuItem value={key}>{value}</MenuItem>
          ))}
        </TextField>
        {fields.map(row => (
          <TextField
            key={row.id}
            variant='outlined'
            type='search'
            id={row.id}
            label={row.label}
            value={row.value}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPageNo(0);
              row.onChange(e.target.value);
            }}
          />
        ))}
        <Box sx={{ width: '300px' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label='Create At Date'
              inputFormat='yyyy-MM-dd'
              openTo='year'
              views={['year', 'month', 'day']}
              value={documentDate || null}
              onChange={newValue => {
                if (newValue !== null && typeof newValue !== 'undefined') {
                  // eslint-disable-next-line no-restricted-globals
                  if (!isNaN(new Date(newValue).getTime())) {
                    const date = format(new Date(newValue), 'yyyy-MM-dd');
                    setDocumentDate(date);
                  }
                }
              }}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px', width: '300px' }
              }}
              renderInput={(params: any) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant='outlined'
                  InputLabelProps={{
                    style: { fontSize: '1.5vh', fontWeight: 'bold', Width: '300px' }
                  }}
                />
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={productData}
          columns={DATAGRID_COLUMN as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.pdf_metadata_id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
          }}
          onPageChange={setPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <PageEditDialog
            closeEdit={closeEditDialog}
            productData={selectedProductData}
            original={1}
          />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(PageNumberCuration);
