import React, { useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import MeetingTimeline from './components/MeetingTimeline';
import KeyDocuments from './components/KeyDocuments';
import useMeetingData from './hooks/useMeetingData';
import {
  LinearProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent
} from '@mui/material';

interface ApplicationDetailsProps {
  match: {
    params: {
      key: string;
    };
  };
}

const ApplicationDetails: React.FC<ApplicationDetailsProps> = ({ match }) => {
  const history = useHistory();
  const [priority, setPriority] = useState<string>('All'); // New state for priority filter
  const [pdfMetaData, setPdfMetaData] = useState<any>({
    intialPageNo: 0,
    documentId: '',
    category: ''
  });
  const [blobUrl, setBlobUrl] = useState<any>('');

  const handleBlobUrl = (fileUrl: string) => {
    setBlobUrl(fileUrl);
  };

  const handlePdfPageNo = (source: any, meetingComments: any, meetingDate: any) => {
    let pageNo = Number(source.split('#')[1]) - 1 || 0;
    pageNo = pageNo < 0 ? 0 : pageNo;
    const documentMetadata = (source.split('/') || []).pop() || '';
    const endIndex = documentMetadata.indexOf('#');
    const documentId = documentMetadata.slice(0, endIndex);
    let category;
    if (documentMetadata.includes('Letter')) {
      category = 'Letters';
    } else if (documentMetadata.includes('Review')) {
      category = 'Reviews';
    } else {
      category = 'Others';
    }
    setPdfMetaData({
      intialPageNo: pageNo,
      documentId: documentId,
      category: category,
      phraseToHighlight: meetingComments,
      textToHighlight: [meetingDate]
    });
  };

  const appKey = match?.params?.key;
  const submissionProps = appKey?.split('-');

  const { meetingsData, saveMeetingData, updateMeetingData, loading } = useMeetingData(appKey);

  // Handle priority change
  const handlePriorityChange = (event: SelectChangeEvent) => {
    setPriority(event.target.value as string);
  };

  // Filter meetings based on selected priority
  const filteredMeetingsData = useMemo(() => {
    if (!priority || priority === 'All') return meetingsData; // If no priority selected, return all meetings

    // Filter meetings by qc_priority field
    return Object.entries(meetingsData || {}).reduce((filtered, [date, meetings]: any) => {
      const filteredMeetings = Object.fromEntries(
        Object.entries(meetings).filter(([, meeting]: any) => meeting.qc_priority === priority)
      );
      if (Object.keys(filteredMeetings).length > 0) {
        filtered[date] = filteredMeetings;
      }
      return filtered;
    }, {} as any);
  }, [meetingsData, priority]);

  return (
    <Box mt={10} pl={2}>
      {loading ? (
        <LinearProgress sx={{ width: '100%', maxWidth: '600px' }} color='secondary' />
      ) : (
        <>
          <Box pb={2}>
            <Stack
              direction='row'
              alignItems='center'
              onClick={() => history.goBack()}
              sx={{ cursor: 'pointer' }}>
              <KeyboardBackspaceIcon sx={{ fontSize: 32 }} />
              <Typography variant='h5'>Go Back</Typography>
            </Stack>
          </Box>

          {/* Priority Filter */}
          <Box mb={2}>
            <FormControl sx={{ minWidth: 200 }}>
              <InputLabel id='priority-filter-label'>Priority</InputLabel>
              <Select
                labelId='priority-filter-label'
                id='priority-filter'
                value={priority}
                onChange={handlePriorityChange}
                label='Priority'>
                <MenuItem value='All'>
                  <em>All</em>
                </MenuItem>
                <MenuItem value='Low'>Low</MenuItem>
                <MenuItem value='Medium'>Medium</MenuItem>
                <MenuItem value='High'>High</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
            <Box flex={1} overflow='auto' pt={1}>
              <Typography variant='h6' color='black'>
                Regulatory Information for {appKey}
              </Typography>
              <MeetingTimeline
                meetings={filteredMeetingsData} 
                updateMeetingData={updateMeetingData}
                saveMeetingData={saveMeetingData}
                handlePdfPageNo={handlePdfPageNo}
              />
            </Box>

            <Box flex={1} pr={1}>
              <KeyDocuments
                applno={submissionProps[0].trim()}
                submissionType={submissionProps[1].trim()}
                submissionNumber={submissionProps[2].trim()}
                pdfMetaData={pdfMetaData}
                blobUrl={blobUrl}
                handleBlobUrl={handleBlobUrl}
              />
            </Box>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default React.memo(ApplicationDetails);
