import React, { useState, useCallback, useContext } from 'react';
import { debounce } from 'lodash';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { updateApplications, getAllApplicationsBySuffix } from '../../../api/australia';

const useApplicationData = () => {
  const [loading, setLoading] = useState(false);
  const [trimType, setTrimType] = useState('');
  const [trimValue, setTrimValue] = useState('');
  const [matchList, setMatchList] = useState([]);
  const [toggleOriginalProductName, setToggleOriginalProductName] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);

  const { dispatch } = useContext<any>(Store);

  const dispatchAlert = (type: any, message: any) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message, color: type }
    });
  };

  const debouncedFetchData = useCallback(
    debounce(async (type, value) => {
      if (!value) {
        setMatchList([]);
        return;
      }
      try {
        const payload = {
          word: value,
          columnName: toggleOriginalProductName ? 'original_product_name' : 'product_name'
        };
        const res = await getAllApplicationsBySuffix(type, payload);
        if (res.status === 200) {
          setMatchList(res.data.body.applications);
          if (res.data.body?.applications?.length === 0) {
            dispatchAlert('info', 'No data available');
          }
          const artgIds = res.data.body.applications?.map((item: any) => item.artg_id);
          setRowSelectionModel(artgIds); // Select all rows by default
        } else {
          throw new Error('No data available');
        }
      } catch (error: any) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: error.message }
        });
      }
    }, 500),
    [dispatch, toggleOriginalProductName]
  );

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleOriginalProductName(event.target.checked);
  };

  const handleTrimChange = async (e: any, type: any) => {
    setTrimValue(e.target.value);
    if (e.target.value === '') {
      setTrimType('');
      setMatchList([]);
      debouncedFetchData.cancel();
      return;
    }
    setTrimType(type);
    setMatchList([]);
  };

  const handleUpdate = async (updates: any) => {
    const res = await updateApplications({ results: updates });
    if (res.status === 200) {
      const applicationsUpdated = res.data.body?.length ?? 0;
      dispatchAlert('success', `${applicationsUpdated} products were successfully updated`);
    } else {
      dispatchAlert('error', `Error occurred while updating products`);
    }
  };

  const datagridSelection = (newRowSelectionModel: any) => {
    setRowSelectionModel(newRowSelectionModel);
  };

  return {
    trimType,
    setTrimType,
    trimValue,
    setTrimValue,
    matchList,
    setMatchList,
    rowSelectionModel,
    setRowSelectionModel,
    handleTrimChange,
    debouncedFetchData,
    dispatchAlert,
    handleUpdate,
    datagridSelection,
    loading,
    setLoading,
    toggleOriginalProductName,
    handleToggleChange
  };
};

export default useApplicationData;
