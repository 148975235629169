import { useState } from 'react';
import { getLabel } from '../../../api/submission_details';

const useLabel = () => {
  const [pdfUrl, setPDFUrl] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrorMessage] = useState<string>('');

  const getLabelPdf = async (
    applnNum: string,
    submissionType: string,
    submissionNumber: any,
    center: string,
    documentId: number
  ) => {
    setLoading(true);
    setPDFUrl('');
    setErrorMessage('');
    try {
      const response = await getLabel(
        applnNum,
        submissionType,
        submissionNumber,
        center,
        documentId
      );
      if (response?.data?.Status === 200) {
        setPDFUrl(response?.data?.Success?.pdf_link);
      } else {
        setErrorMessage('Could not find Label for the given submission');
      }
    } catch (err) {
      setErrorMessage('Could not find Label for the given submission');
    } finally {
      setLoading(false);
    }
  };

  return {
    pdfUrl,
    loading,
    errMessage,
    getLabelPdf
  };
};

export default useLabel;
