import Snapshot from '../components/Snapshot/Snapshot';
import SnapshotCuration from '../components/Snapshot';

const SNAPSHOT_ROUTES = [
  {
    id: 'snapshotDataEntry',
    path: '/snapshot',
    exact: true,
    component: Snapshot
  },
  {
    id: 'snapshotCurationReview',
    path: '/snapshot/:center/:key/:documentId',
    exact: true,
    component: SnapshotCuration
  }
];

export default SNAPSHOT_ROUTES;
