import React, { useState, useEffect, useContext } from 'react';

// muiv5
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button
} from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import LinearProgress from '@mui/material/LinearProgress';
import { Auth } from 'aws-amplify';

import Actions from '../../store/actions';
import Store from '../../store';
import PageEditDialog from './PageEditDialog';

// api
import { fetchLabelDocs, submitLabelCurationDetails } from '../../api/Hma';

// styles
import styles from './styles/labelSeparation.styles';
import openDocxOrPdfViewer from '../../utils/openDocxOrPdfViewer';

interface LabelData {
  onedrive_file_url: string;
  onedrive_doc_url: string;
  onedrive_doc_name: string;
  onedrive_doc_id: string;
  s3_path: string;
  document_link: string;
}
const LabelSectionDialog = ({ closeEdit, productData }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [details, setDetails] = useState<LabelData | null>();
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [EditFormDialog, setEditFormDialog] = useState(false);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    async function fetchData() {
      try {
        window.onbeforeunload = () => {
          return 'Please wait for the data to load...';
        };
        const res = await fetchLabelDocs(productData.pdf_metadata_id, productData.document_hash);
        if (res === undefined || res.status !== 200) {
          throw new Error('Result Not Available');
        }
        if (res.data.body && res.data.body.label_data) {
          setLoading(false);
          setDetails(res.data.body.label_data);
        } else {
          setLoading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);
  const handleOpenDocEdit = () => {
    openDocxOrPdfViewer(details?.onedrive_file_url ?? '', () => setOpenSubmitConfirmation(true));
  };

  useEffect(() => {
    async function fetchData() {
      try {
        if (details && details.onedrive_file_url) {
          handleOpenDocEdit();
        } else if (details && !details.onedrive_file_url) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'No Document Available' }
          });
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    }
    fetchData();
  }, [details]);

  const handleOpenOriginalPDF = () => {
    const url = details?.document_link ?? '';
    openDocxOrPdfViewer(url, () => setOpenSubmitConfirmation(true));
  };

  const handleSubmitChanges = async () => {
    try {
      setOpenSubmitConfirmation(false);
      setLoading(true);
      const user = await Auth.currentUserInfo();
      const payload = {
        filename: details?.onedrive_doc_name,
        s3_path: details?.s3_path,
        fileId: details?.onedrive_doc_id,
        application_docs_id: productData.pdf_metadata_id,
        document_hash: productData.document_hash,
        product_number: productData.product_number,
        curator_name: user.attributes['custom:user'],
        curator_email: user.attributes.email
      };
      const res = await submitLabelCurationDetails(payload);
      if (res?.data?.status !== 200) {
        setLoading(false);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.message }
        });
      } else if (res.data.body) {
        setLoading(false);
        setEditFormDialog(true);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully Submiited the File', color: 'success' }
        });
      }
      setEditFormDialog(true);
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    } finally {
      setLoading(false);
    }
  };
  const formcloseEdit = () => {
    setEditFormDialog(false);
  };
  return (
    <div>
      <Container sx={styles.root}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box display='flex' alignItems='center'>
            <Button
              sx={{ background: '#3AB09E', color: 'whitesmoke' }}
              variant='contained'
              disabled={loading}
              onClick={handleOpenOriginalPDF}>
              View Original PDF
            </Button>
          </Box>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2}>
          {loading && (
            <Box
              sx={{
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <LinearProgress
                sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
                color='secondary'
              />
              <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>
                {!details ? 'Loading data ....' : 'Submitting changes ....'}
              </Typography>

              <Typography sx={{ fontSize: '20px', color: '#3AB09E' }}>
                {details && '(this might take couple of minutes to complete)'}
              </Typography>
            </Box>
          )}
          <Grid item xs={12} lg={12}>
            {!loading && details && details.onedrive_file_url && (
              <Box
                display='flex'
                flexDirection='column'
                minHeight='50vh'
                justifyContent='center'
                alignItems='center'>
                <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>
                  File is open in a new popup window
                </Typography>
                <Button
                  sx={{ background: '#3AB09E', color: 'whitesmoke' }}
                  onClick={handleOpenDocEdit}
                  variant='contained'
                  disabled={loading}>
                  Open file again.
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <Dialog fullWidth maxWidth='xs' open={openSubmitConfirmation}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm submission
            </Typography>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            Are you sure you have edited the document accurately?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={handleOpenDocEdit}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Open file again
          </Button>
          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={handleSubmitChanges}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={EditFormDialog}
        onClose={formcloseEdit}
        sx={{ backdropFilter: 'blur(5px)' }}
        maxWidth='lg'
        fullWidth>
        <PageEditDialog closeEdit={closeEdit} productData={productData} original={0} />
      </Dialog>
    </div>
  );
};

export default React.memo(LabelSectionDialog);
