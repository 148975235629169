/* eslint-disable import/prefer-default-export */
import makeRequest from './client';

export const getAustraliaProductInformationDocuments = (
  pageSize: number,
  pageNo: number,
  status: string,
  vinNumber: string,
  processed: string = 'false'
) => {
  const baseURL = `/australia/products`;
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`, `processed=${processed}`];
  if (status !== 'All Documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (vinNumber) {
    queryParams.push(`vin=${vinNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getAllApplications = (pageNo: number, pageSize: number, payload: any = null) => {
  const baseURL = `/australia/applications`;
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  if (payload) {
    return makeRequest(finalURL, 'POST', payload);
  }
  return makeRequest(finalURL, 'GET');
};

export const getAllApplicationsBySuffix = (type: string, payload: any) => {
  return makeRequest(`/australia/applications/${type}`, 'POST', payload);
};

export const updateApplications = (payload: any) => {
  return makeRequest(`/australia/applications/batch-update`, 'PUT', payload);
};

export const getOrUpdateProductInfoByID = (
  id: string,
  method = 'GET',
  payload = {},
  processed = 'false'
) => {
  if (method === 'GET') {
    return makeRequest(
      `/australia/products/${id}/page-number-curation?processed=${processed}`,
      'GET'
    );
  }
  return makeRequest(
    `/australia/products/${id}/page-number-curation?processed=${processed}`,
    'PUT',
    payload
  );
};

export const getOrUpdateProductInfoSectionByID = (id: string, method = 'GET', payload = {}) => {
  if (method === 'GET') {
    return makeRequest(`/australia/products/${id}/section-seperation`, 'GET');
  }
  return makeRequest(`/australia/products/${id}/section-seperation`, 'PUT', payload);
};

export const insertProductInfoPageNumbers = (payload: any, processed: string = 'false') =>
  makeRequest(`/australia/products/page-number-curation?processed=${processed}`, 'POST', payload);
