import ProductInfoPageNoCuration from '../pages/Australia/components/ProductInfoCuration';
import ProcuctInfoSectionCuration from '../pages/Australia/components/ProcuctInfoSectionCuration';
import ProductNameCuration from '../pages/Australia/components/ProductNameCuration';

const AUSTRALIA_ROUTES = [
  {
    id: 'productInformationCuration',
    path: '/australia/product_information',
    component: ProductInfoPageNoCuration,
    exact: true
  },
  {
    id: 'labelSeparationCuration',
    path: '/australia/label_separation',
    component: ProcuctInfoSectionCuration,
    exact: true
  },
  {
    id: 'productNameCuration',
    path: '/australia/product_name',
    component: ProductNameCuration,
    exact: true
  }
];
export default AUSTRALIA_ROUTES;
