import SectionSeparationDashboard from '../components/HMA/SectionSeparationDashboard';
import PageNumberCuration from '../components/HMA/PageNumberCuration';

const HMA_ROUTES = [
  {
    id: 'hmaPageNumberCuration',
    path: '/hma/page',
    component: PageNumberCuration,
    exact: true
  },
  {
    id: 'hmaSectionSeperation',
    path: '/hma/section',
    component: SectionSeparationDashboard,
    exact: true
  }
];
export default HMA_ROUTES;
