import React, { useState, useEffect } from 'react';

import {
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  Button,
  Link,
  Divider,
  Switch,
  FormControlLabel
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { logicOptions, statusOptions, artgWebsitePrefix } from '../constants';

import useProductNameData from '../hooks/useProductNameData';
import ProductNameGridByAffix from './ProductNameGridByAffix';

const ProductNameCurationDialog = ({
  productData,
  closeEdit
}: {
  productData: any;
  closeEdit: any;
}) => {
  const [productName, setProductName] = useState(productData.product_name || '');
  const [logicUsed, setLogicUsed] = useState(productData.logic_used || '');
  const [status, setStatus] = useState(productData.status || '');

  const {
    trimType,
    trimValue,
    matchList,
    rowSelectionModel,
    debouncedFetchData,
    handleTrimChange,
    handleUpdate,
    datagridSelection,
    loading,
    setLoading,
    toggleOriginalProductName,
    handleToggleChange
  } = useProductNameData();

  // Effect that triggers debounced API call when trimType or trimValue changes
  useEffect(() => {
    if (trimType && trimValue) {
      debouncedFetchData(trimType, trimValue);
    }
  }, [trimType, trimValue, debouncedFetchData]);

  const handleSubmit = async () => {
    const updateData = { product_name: productName, logic_used: logicUsed, status };
    handleUpdate([{ artg_id: productData.artg_id, update_data: updateData }]);
    closeEdit();
  };

  // Apply changes based on the selection model, and update accordingly.
  const applyChanges = async () => {
    setLoading(true);
    // Filter matchList based on selectionModel of datagrid; include all if selectionModel is empty
    const filteredUpdates =
      rowSelectionModel.length > 0
        ? matchList.filter((item: any) => rowSelectionModel.includes(item.artg_id))
        : matchList;

    const updates = filteredUpdates.map((item: any) => {
      const productNameToUse = toggleOriginalProductName
        ? item.original_product_name
        : item.product_name;
      // Check if the product name matches the trim value exactly.
      if (productNameToUse.toLowerCase().trim() === trimValue.toLowerCase().trim()) {
        return {
          artg_id: item.artg_id,
          update_data: {
            product_name: productNameToUse.trim(), // Keep original name unchanged.
            status: 'qced'
          }
        };
      }
      // Calculate the new product name based on the type of trim required.
      const updatedProductName =
        trimType === 'prefix'
          ? productNameToUse.slice(0, trimValue.length)
          : productNameToUse.slice(0, productNameToUse.length - trimValue.length);
      return {
        artg_id: item.artg_id,
        update_data: {
          product_name: updatedProductName.trim(),
          status: 'qced'
        }
      };
    });
    await handleUpdate(updates);
    setLoading(false);
    closeEdit();
  };

  return (
    <>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='subtitle1' sx={{ color: 'primary.main', fontWeight: 'bold' }}>
            Product Name Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={closeEdit}
            sx={{
              color: 'grey.main'
            }}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Divider />
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={4}>
            <Stack spacing={2} alignItems='flex-start'>
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  ARTG ID:
                </Typography>
                <Link href={`${artgWebsitePrefix}${productData.artg_id}`} target='_blank'>
                  <Typography>{productData.artg_id}</Typography>
                </Link>
              </Stack>
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  Original Product Name:
                </Typography>
                <Typography variant='body1'>{productData.original_product_name}</Typography>
              </Stack>

              {/* Editable text field for product_name */}
              <Stack spacing={1} sx={{ width: '100%' }}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  Extracted Product Name:
                </Typography>
                <TextField
                  fullWidth
                  variant='outlined'
                  size='small'
                  value={productName}
                  onChange={e => setProductName(e.target.value)}
                />
              </Stack>

              {/* Switch to toggle original product name */}
              <Stack sx={{ borderRadius: 3, border: '1px solid gray', width: '100%', p: 2 }}>
                <Stack spacing={1} alignItems='flex-end'>
                  <FormControlLabel
                    control={
                      <Switch
                        size='small'
                        checked={toggleOriginalProductName}
                        onChange={handleToggleChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                    }
                    label={
                      <Typography variant='subtitle2'>
                        {toggleOriginalProductName ? 'Original' : 'Extracted'} Product Name
                      </Typography>
                    }
                  />
                </Stack>
                <Stack spacing={1} sx={{ width: '100%' }}>
                  <Typography variant='subtitle2' fontWeight='bold'>
                    Suffix to be removed:
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    value={trimType === 'suffix' ? trimValue : ''}
                    onChange={e => handleTrimChange(e, 'suffix')}
                    disabled={trimType !== '' && trimType !== 'suffix'}
                  />
                </Stack>

                <Stack spacing={1} sx={{ width: '100%' }}>
                  <Typography variant='subtitle2' fontWeight='bold'>
                    Prefix to be kept:
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    size='small'
                    value={trimType === 'prefix' ? trimValue : ''}
                    onChange={e => handleTrimChange(e, 'prefix')}
                    disabled={trimType !== '' && trimType !== 'prefix'}
                  />
                </Stack>
              </Stack>
              {/* Select dropdown for logic_used */}
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  Logic Used:
                </Typography>
                <Select
                  value={logicUsed}
                  onChange={e => setLogicUsed(e.target.value)}
                  size='small'
                  variant='outlined'>
                  {logicOptions
                    .filter((item: any) => item.value !== 'all')
                    .map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {/* Add more options as needed */}
                </Select>
              </Stack>

              {/* Select dropdown for logic_used */}
              <Stack spacing={1}>
                <Typography variant='subtitle2' fontWeight='bold'>
                  Status:
                </Typography>
                <Select
                  value={status}
                  onChange={e => setStatus(e.target.value)}
                  size='small'
                  variant='outlined'>
                  {statusOptions
                    .filter((item: any) => item.value !== 'all')
                    .map((option: any) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  {/* Add more options as needed */}
                </Select>
              </Stack>
              {/* Submit button to call update API */}
              <Button
                variant='contained'
                color='primary'
                onClick={handleSubmit}
                sx={{ marginTop: 2 }}>
                Submit
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} lg={8}>
            <ProductNameGridByAffix
              matchList={matchList}
              trimType={trimType}
              trimValue={trimValue}
              loading={loading}
              applyChanges={applyChanges}
              rowSelectionModel={rowSelectionModel}
              datagridSelection={datagridSelection}
              toggleOriginalProductName={toggleOriginalProductName}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </>
  );
};

export default ProductNameCurationDialog;
