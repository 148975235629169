import React from 'react';
import { Box } from '@mui/material';
import SectionItemWrapper from './SectionItemWrapper';

interface SectionListProps {
  sections: Record<string, Array<any>>;
  onSave: any;
  onDelete:any;
}

const SectionList: React.FC<SectionListProps> = ({ sections, onSave,onDelete }) => {
  return (
    <Box>
      {Object.keys(sections || {})?.map((key: any) => {
        return (
          <SectionItemWrapper
            key={key}
            header={key}
            sectionData={sections[key]}
            onDelete={onDelete}
            onSaveSectionData={onSave}
          />
        );
      })}
    </Box>
  );
};

export default React.memo(SectionList);
