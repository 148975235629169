const styles = {
  heading: { color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 2,
    color: 'gray.light'
  },
  gridContainer: { overflow: 'auto', height: '70vh', padding: 2 }
};

export default styles;
