import theme from '../../../theme';

const styles = {
  root: {
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    padding: '30px'
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh'
  },

  Heading: {
    color: '#3AB09E',
    fontSize: '2.5vh',
    textAlign: 'left',
    fontWeight: '600'
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  dialog: {
    background: theme.palette.white
  },
  autocompleteChip: {
    border: '1px solid black !important',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.darkBluish
  }
};

export default styles;
