/* eslint-disable import/prefer-default-export */
import makeRequest from './client';

export const getEMACommitteesData = () => makeRequest('/ema_committees/dropdown', 'GET');

export const getEMACommitteesMeetingData = (payload:any) => makeRequest('/ema_committees/detail_data', 'POST',payload);

export const upsertEMACommitteesMeetingData = (payload:any) => makeRequest('/ema_committees/create_update', 'POST',payload);

export const deleteEMACommitteesApplicationData = (payload:any) => makeRequest('/ema_committees/delete', 'DELETE',payload);

export const getEMACommitteesGPTApplicationData = (payload:any) => makeRequest('/ema_committees/gpt_extraction', 'POST',payload);