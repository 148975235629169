import Home from '../components/Home/Home';
import ReleaseUpdate from '../components/Release/ReleaseUpdate';
import REVIEW_ROUTES from './reviews';
import FDA_ROUTES from './fda';
import EMA_ROUTES from './ema';
import PMR_ROUTES from './pmr';
import BOT_QUERIES from './botQueries';
import RLD_ROUTES from './rld';
import STATUS_ROUTE from './status';
import ADCOMM_ROUTES from './adComm';
import SNAPSHOT_ROUTES from './snapshot';
import APPROVAL_ROUTES from './approval';
import SYNONYMS_ROUTES from './synonyms';
import GUIDANCE_ROUTES from './guidance';
import DASHBOARD_ROUTES from './dashboard';
import DBSTATS_ROUTES from './dbStats';
import EUA_ROUTES from './eua';
import CBER_ROUTES from './cber';
import REG_INFO_ROUTES from './regulatoryInfo';
import UPDATE_GUIDANCE_ROUTES from './update_guidance';
import DATA_QUALITY_ROUTES from './dataQuality';
import CHAT_RIA_STATS_ROUTES from './chatRiaStats';
import CANADA_ROUTES from './canada';
import PMDA_ROUTES from './pmda';
import HPRA_ROUTES from './hpra';
import USER_UPLOAD_ROUTES from './userUpload';
import SourceValidationRoutes from './sourceValidation';
import DATA_QUALITY_STATISTICS_ROUTES from './dataQualityStatistics';
import USER_SURVEY_ROUTES from './userSurvey';
import WHATS_NEW_ROUTES from './whatsNew';
import USER_NOTIFICATION_ROUTES from './user_notifications';
import AUSTRALIA_ROUTES from './australia';
import HMA_ROUTES from './hma';

const ADMIN_ROUTES = [
  ...SourceValidationRoutes,
  ...REVIEW_ROUTES,
  ...PMR_ROUTES,
  ...FDA_ROUTES,
  ...EMA_ROUTES,
  ...BOT_QUERIES,
  ...RLD_ROUTES,
  ...STATUS_ROUTE,
  ...ADCOMM_ROUTES,
  ...SNAPSHOT_ROUTES,
  ...APPROVAL_ROUTES,
  ...SYNONYMS_ROUTES,
  ...GUIDANCE_ROUTES,
  ...DASHBOARD_ROUTES,
  ...DBSTATS_ROUTES,
  ...EUA_ROUTES,
  ...CBER_ROUTES,
  ...REG_INFO_ROUTES,
  ...UPDATE_GUIDANCE_ROUTES,
  ...DATA_QUALITY_ROUTES,
  ...CHAT_RIA_STATS_ROUTES,
  ...CANADA_ROUTES,
  ...PMDA_ROUTES,
  ...HPRA_ROUTES,
  ...USER_UPLOAD_ROUTES,
  ...DATA_QUALITY_STATISTICS_ROUTES,
  ...USER_SURVEY_ROUTES,
  ...WHATS_NEW_ROUTES,
  ...USER_NOTIFICATION_ROUTES,
  ...AUSTRALIA_ROUTES,
  ...HMA_ROUTES,
  {
    id: 'adminHome',
    path: '/',
    component: Home,
    exact: true
  },
  {
    id: 'releaseVersion',
    path: '/release',
    component: ReleaseUpdate,
    exact: true
  }
];
export default ADMIN_ROUTES;
