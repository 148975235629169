import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MeetingItem from './MeetingItem';

const MeetingTimeline: React.FC<any> = ({
  meetings,
  updateMeetingData,
  saveMeetingData,
  handlePdfPageNo
}) => {
  // State to track which meeting dates are expanded
  const [expandedDates, setExpandedDates] = useState<Record<string, boolean>>({});

  // Toggle the visibility of the MeetingItem for a specific date
  const handleToggleMeetingItem = (meetingDate: string) => {
    setExpandedDates((prev) => ({
      ...prev,
      [meetingDate]: !prev[meetingDate], // Toggle the boolean value for the clicked date
    }));
  };

  return (
    <Box mt={2}>
      {Object.entries(meetings).map(([meetingDate, meetingItems]) => (
        <Box key={meetingDate} mb={2}>
          {/* Meeting Date Row */}
          <Box display="flex" alignItems="center" mb={1}>
            <IconButton onClick={() => handleToggleMeetingItem(meetingDate)} size="small">
              {expandedDates[meetingDate] ? (
                <RemoveCircleOutlineIcon color="primary" />
              ) : (
                <ControlPointIcon color="primary" />
              )}
            </IconButton>
            <Typography variant="h6">
              <b>Meetings on:</b> {meetingDate}
            </Typography>
          </Box>

          {/* Show MeetingItem if the date is expanded */}
          {expandedDates[meetingDate] && (
            <MeetingItem
              meetingDate={meetingDate}
              meetingItems={meetingItems}
              updateMeetingData={updateMeetingData}
              saveMeetingData={saveMeetingData}
              handlePdfPageNo={handlePdfPageNo}
            />
          )}
        </Box>
      ))}

    </Box>
  );
};

export default React.memo(MeetingTimeline);
